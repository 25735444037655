<template>
  <div v-show="!isHidden" :style="computedStyle">
    <el-form-item
      :prop="name"
      :label="label"
      :class="elFormCssClasses"
      :style="elFormCss"
      :rules="[
        { required: _isRequired, message: $locale.main.message.required_field, trigger: 'blur' },
        rules.controlValues,
        rules.controlNotificValues
      ]"
    >
      <span slot="label" :class="labelCssClasses" :style="labelCss">
        {{ label }}
        <el-tooltip v-if="tooltip" class="item" effect="dark" :content="tooltip">
          <i class="el-icon-question"></i>
        </el-tooltip>
      </span>

      <el-date-picker
        v-model="localValue"
        class="date_field_width"
        :type="type"
        :name="name"
        :size="size"
        :readonly="_isReadonly"
        :placeholder="placeholder || $locale.main.placeholder.date_field"
        :clearable="clearable"
        :editable="clearable"
        :format="isMonth ? 'MMM.yyyy' : 'dd.MM.yyyy'"
        value-format="yyyy-MM-dd"
        :picker-options="{ firstDayOfWeek: 1 }"
        @input="input($event)"
      ></el-date-picker>
    </el-form-item>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import registryMixin from '../registry/registry_mixins'
import controlValues from '../control_values_mixin'
import controlNotification from '../control_notification_mixin.js'
import InputLabel from '@/mixins/inputLabel.js'

export default {
  name: 'a-date',
  inject: {
    forceUpdateSettingsPanel: {
      default: () => () => {}
    }
  },
  mixins: [mixin, registryMixin, controlValues, controlNotification, InputLabel],
  props: {
    editorAlias: {
      type: String,
      description: 'alias'
    },
    controlValues: {
      type: Object,
      editor: 'ControlValues',
      description: 'control_of_values',
      default: () => {
        return {
          operator: null,
          type: 'date',
          conditions: []
        }
      },
      options: { type: 'date' }
    },
    controlNotificValues: {
      type: Object,
      description: 'control_notific_values',
      editor: 'ControlNotificationValues',
      default: () => {
        return {
          operator: null,
          type: 'date',
          conditions: []
        }
      },
      options: { type: 'date' }
    },
    name: {
      type: String,
      description: 'attribute',
      options: {
        removeSpaces: true
      }
    },
    defaultValue: {
      type: Boolean,
      description: 'today_default'
    },
    defaultValueModifier: {
      type: Number,
      description: 'before_today'
    },
    label: {
      type: String,
      description: 'name'
    },
    placeholder: {
      type: String,
      description: 'placeholder'
    },
    tooltip: {
      type: String,
      description: 'tooltip'
    },
    isMonth: {
      description: 'only_month',
      type: Boolean
    },
    isMultiple: {
      description: 'multiple_date',
      type: Boolean
    },
    isShow: {
      type: Boolean,
      description: 'hide'
    },
    clearable: {
      type: Boolean,
      default: true,
      description: 'clearable'
    }
  },
  computed: {
    computedStyle () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (this.width && !this.block) {
        css += ';width:' + this.width
      }
      if (!this.block) {
        css += `;display: inline-block; width:${this.width || '200px'}`
      }
      if (this.wrapper) {
        css += ';display: block;'
      }

      return css
    },
    type () {
      if (this.isMonth && this.isMultiple) {
        return 'months'
      } else if (this.isMonth && !this.isMultiple) {
        return 'month'
      } else if (!this.isMonth && this.isMultiple) {
        return 'dates'
      }
      return 'date'
    }
  },
  watch: {
    editorAlias () {
      this.forceUpdateSettingsPanel()
    }
  },
  data () {
    return {
      localValue: null,
      dateTypes: {
        months: 'months',
        month: 'month',
        date: 'date',
        dates: 'dates'
      }
    }
  },
  mounted () {
    if (this.defaultValue) {
      let date = new Date()
      let days = this.defaultValueModifier ? this.defaultValueModifier : 0
      date.setDate(date.getDate() + days)
      let dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      this.$set(this, 'localValue', dateString)
      this.$emit('input', dateString)
    } else {
      if (this.type === this.dateTypes.months || this.type === this.dateTypes.dates) {
        this.$set(this, 'localValue', this.value.split(','))
        this.$emit('input', this.value.split(','))
      } else {
        this.$set(this, 'localValue', this.value)
        this.$emit('input', this.value)
      }
    }
  },
  methods: {
    input (value) {
      if (typeof value === 'string') {
        this.localValue = value
        this.$emit('input', value)
      } else {
        if (Array.isArray(value)) {
          this.$emit('input', value.toString())
        } else {
          this.$emit('input', value)
        }
        this.localValue = value
      }
    }
  }
}
</script>

<style scoped>
  .date_field_width.el-date-editor.el-input{
    width: 100%;
  }
</style>
