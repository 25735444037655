export default {
  data () {
    return {
      rules: {
        controlValues: {
          validator: (rules, value, callback) => {
            if (value && this.controlValues.conditions.length) {
              let numberInput = null
              if (this.controlValues.type && this.controlValues.type === 'date') {
                numberInput = new Date(value)
              } else {
                numberInput = +value
              }
              this.controlValues.conditions.forEach(item => {
                let attribute = null
                if (item.attribute) {
                  if (this.controlValues.type && this.controlValues.type === 'date') {
                    attribute = new Date(this.getModel()[item.attribute]).getTime()
                  } else {
                    attribute = +this.getModel()[item.attribute]
                  }
                }
                if (!item.mathOperations || !item.type) {
                  callback()
                  return false
                }
                // or
                if (this.controlValues.operator !== 'and' || this.controlValues.conditions.length === 1) {
                  // console.log('or')
                  this.switch('or', numberInput, attribute, item, callback)
                  // and
                } else {
                  // console.log('and')
                  this.switch('and', numberInput, attribute, item, callback)
                }
              })

              if (this.controlValues.operator === 'and') {
                let result = this.controlValues.conditions
                  .filter((item) => !item.result)
                  .map(text => text.textUser)
                if (result.length) {
                  callback(new Error(result.join(', ')))
                } else {
                  callback()
                }
              } else {
                callback()
              }
            } else {
              callback()
            }
          },
          trigger: 'blur'
        }
      }
    }
  },
  methods: {
    switch (operator, numberInput, attribute, item, callback) {
      let flag = (operator === 'or')
      let numberControl
      if (typeof numberInput === 'object' || attribute) {
        if (item.value) {
          numberControl = new Date(item.value).getTime()
          numberInput = numberInput.getTime()
        } else {
          if (typeof numberInput === 'object') {
            numberInput = numberInput.getTime()
          }
          numberControl = attribute
        }
      } else {
        numberControl = String(item.value || attribute).replace(/,/, '.')
        numberControl = Number(numberControl)
      }
      switch (item.mathOperations) {
        case 'equally':
          if (flag) {
            if (numberInput === (numberControl)) {
              callback(new Error(item.textUser))
            }
          } else {
            item.result = numberInput === (numberControl)
          }
          break
        case 'not_equally':
          if (flag) {
            if (numberInput !== (numberControl)) {
              callback(new Error(item.textUser))
            }
          } else {
            item.result = numberInput !== (numberControl)
          }
          break
        case 'less':
          if (flag) {
            if (numberInput < (numberControl)) {
              callback(new Error(item.textUser))
            }
          } else {
            item.result = numberInput < (numberControl)
          }
          break
        case 'more':
          if (flag) {
            if (numberInput > (numberControl)) {
              callback(new Error(item.textUser))
            }
          } else {
            item.result = numberInput > (numberControl)
          }
          break
        case 'less_equal':
          if (flag) {
            if (numberInput <= (numberControl)) {
              callback(new Error(item.textUser))
            }
          } else {
            item.result = numberInput <= (numberControl)
          }
          break
        case 'more_equal':
          if (flag) {
            if (numberInput >= (numberControl)) {
              callback(new Error(item.textUser))
            }
          } else {
            item.result = numberInput >= (numberControl)
          }
          break
        default:
          callback()
          break
      }
    }
  }
}
